import React from 'react'
import { css } from '@emotion/react';
import Layout from '../components/layout';

export default function NotFound() {
  return (
    <h1
      className="type--large"
      css={css`
        margin-top: var(--gutter);
      `}
    >Sorry the document you are looking for could not be found.</h1>
  )
}
